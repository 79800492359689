import Ad from 'components/Ad';
import Comment from 'components/Comment';
import Content from 'components/Content';
import Pagination from 'components/Pagination';
import Social from 'components/Social';
import Editor from 'components/Editor';
import site from 'data/site.json';
import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import SEO from 'utils/SEO';

const Post = styled.div`
	display: grid;
	grid-gap: ${theme.size(2)};
	& > * {
		background: ${theme.colors.background[0]};
		border-radius: ${theme.size(0.5)};
	}
	.canonical {
		${theme.type.p2};
	}
	${theme.media.notlarge} {
		.ad {
			width: calc(100% + ${theme.size(4)} + ${theme.size(4)});
			margin-left: -${theme.size(4)};
			margin-bottom: ${theme.size(4)};
			> * {
				margin: 0 auto;
				display: block;
			}
		}
	}
	small a {
		&:hover {
			text-decoration: underline;
		}
	}
`;
const Byline = styled.div`
	${theme.type.p2};
	text-transform: uppercase;
	margin-bottom: ${theme.size(2)};
	> * {
		display: inline-block;
		margin-right: ${theme.size(2)};
	}
	.category {
		color: ${theme.colors.base[0]};
		font-weight: bold;
	}
	.date {
		color: ${theme.colors.foreground[1]};
	}
`;
const Text = styled.div`
	display: inline-block;
	width: 100%;
	${theme.media.large} {
		p {
			display: inline;
		}
		& > [data-type] {
			display: none;
		}
		& > [data-type='in_content'],
		& > [data-type='leaderboard_bot'] {
			display: inline;
			float: left;
			width: auto;
			margin: ${theme.size(4)};
			margin-left: 0;
		}
		& > [data-type='in_content'] {
			margin-top: ${theme.size(2)};
		}
		& > [data-type='leaderboard_bot'] {
			margin-left: -${theme.size(4)};
		}
	}
`;
const PaginationWrapper = styled.div``;

const renderContent = function(content, index) {
	switch (content.type) {
		case 'paragraph':
		case 'list_item_detail':
			if (
				content.text.toLowerCase().indexOf('boredpanda') > -1 ||
				content.text.toLowerCase().indexOf('bored panda') > -1 ||
				content.text.toLowerCase().indexOf('more info:') > -1
			) {
				return false;
			}
			return <p dangerouslySetInnerHTML={{ __html: `${content.text}<br/><br/>` }} key={index} />;
		case 'image':
			return <img src={content.attachment.attachmentUrl} alt="" key={index} />;
		default:
			return false;
	}
};

const renderPagination = function(prev, next) {
	return <Pagination prev={prev} next={next} />;
};

export default props => {
	const post = props.pageContext.data;
	const postIndex = props.pageContext.index;
	return (
		<Post>
			{postIndex === false ? (
				<>
					<SEO title={post.title} image={post.facebook_image.large} href={props.path} />
					<Content>
						<Byline>
							{post.categories_raw.slice(0, 3).map((item, i) => (
								<div className="category" href={item.slug} key={i}>
									{item.name}
								</div>
							))}
						</Byline>
						<h1>{post.title}</h1>
						<small>{post.excerpt}</small>
						<img alt={post.title} src={post.cover_image.url} />
						<Editor id={post.id} />
						<Text>
							<Ad type="in_content"></Ad>
							<Ad type="mpu_2"></Ad>
							{post.content.map((item, i) => {
								return renderContent(item, i);
							})}
							<Ad type="leaderboard_bot" />
							<Ad type="mpu_3"></Ad>
						</Text>
						<PaginationWrapper>
							{renderPagination(props.pageContext.prev, props.pageContext.next)}
						</PaginationWrapper>
						<Social
							href={site.domain + '/' + props.pageContext.post}
							image={site.domain + post.facebook_image.large}
						></Social>
					</Content>
				</>
			) : (
				<>
					<SEO
						title={post._submissions[postIndex].title}
						image={post._submissions[postIndex].thumbnail_landscape}
						href={props.path}
					/>
					<Content>
						{post._submissions[postIndex].title && (
							<h2>
								{postIndex + 1}. {post._submissions[postIndex].title}
							</h2>
						)}
						<small>
							<a className="canonical" href={'/' + props.pageContext.post}>
								{post.title}
							</a>
						</small>
						{post._submissions[postIndex].content
							.filter(item => item.type === 'image')
							.map((item, i) => renderContent(item, i))}

						{post._submissions[postIndex].comments.length && (
							<Comment data={post._submissions[postIndex].comments[0]} />
						)}
						<Editor id={post._submissions[postIndex].id} />
						<Text>
							<Ad type="in_content"></Ad>
							<Ad type="mpu_2"></Ad>
							{(post._submissions[postIndex].custom_text
								? [post._submissions[postIndex].custom_text]
								: post.content
							).map((item, i) => {
								return renderContent(item, i);
							})}
							<Ad type="leaderboard_bot" />
							<Ad type="mpu_3"></Ad>
						</Text>
						<PaginationWrapper>
							{renderPagination(props.pageContext.prev, props.pageContext.next)}
						</PaginationWrapper>
						<Social
							href={site.domain + '/' + props.pageContext.post}
							image={
								site.domain +
								post._submissions[postIndex].content.filter(item => item.type === 'image')[0].attachment
									.attachmentUrl
							}
						></Social>
					</Content>
				</>
			)}
		</Post>
	);
};
