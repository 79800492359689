import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';

const Comment = styled.div`
	display: grid;
	grid-template-areas: 'avatar body';
	grid-template-columns: 48px auto;
	grid-gap: ${theme.size(2)};
	width: auto;
	padding-bottom: ${theme.size(8)};
	img {
		grid-area: avatar;
		border-radius: 100px;
		height: auto;
		width: 100%;
		margin: 0;
	}
	.body {
		grid-area: body;
		position: relative;
		margin-top: ${theme.size(1)};
	}
	.text {
		${theme.type.p1};
		border-radius: ${theme.size(2)};
		background: ${theme.colors.background[1]};
		padding: ${theme.size(2)} ${theme.size(3)};
		width: auto;
	}
	.title {
		${theme.type.p2};
		position: absolute;
		top: calc(100% + ${theme.size(1)});
		left: ${theme.size(3)};
	}
`;

export default props => {
	return (
		<Comment>
			<img src={props.data.author.avatar_url} alt={props.data.author.display_name} />
			<p className="body">
				<div className="text">
					{props.data.content.split('/bpp/')[0]}
					<div className="title">
						<span>{props.data.author.display_name}</span>
					</div>
				</div>
			</p>
		</Comment>
	);
};
