import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';

const Pagination = styled.div`
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-gap: ${theme.size(1)};
	margin: ${theme.size(4)} 0;
	overflow: hidden;
	> * {
		${theme.type.p1};
		text-align: center;
		padding: ${theme.size(6)};
		cursor: pointer;
		border-radius: ${theme.size(1)};
		&:hover {
			opacity:0.8;
		}
		${theme.media.notlarge}{
			span {
				display:none;
			}
		}
	}
	.prev {
		background ${theme.colors.background[1]};
		color ${theme.colors.foreground[1]};
	}
	.next {
		background ${theme.colors.base[1]};
		color ${theme.colors.background[0]};
	}
`;

export default props => {
	return (
		<Pagination>
			<a className="prev" href={props.prev}>
				« <span>Previous</span>
			</a>
			<a className="next" href={props.next}>
				Next <span>Page</span> »
			</a>
		</Pagination>
	);
};
