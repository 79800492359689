import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import Layout from 'components/Layout';
import Ad from 'components/Ad';
import Wrapper from 'components/Wrapper';
import Feed from 'components/Feed';
import Sidebar from 'components/Sidebar';
import Post from 'components/Post';
import TopAd from 'components/TopAd';
import allPostsMin from 'data/allPostsMin';
import shuffle from 'utils/shuffle';

const Page = styled.div`
	display: grid;
	width: 100%;
	grid-gap: ${theme.size(4)};
	${theme.media.large} {
		grid-template-areas: 'main side';
		grid-template-columns: minmax(0, 1fr) auto;
	}
	${theme.media.notlarge} {
		grid-template-areas: 'main' 'side';
		grid-template-columns: 100%;
	}
`;
const Main = styled.div`
	grid-area: main;
	display: grid;
	grid-gap: ${theme.size(4)};
	.feed {
		background: ${theme.colors.background[0]};
		border-radius: ${theme.size(0.5)};
	}
`;
const Side = styled.div`
	grid-area: side;
`;
const MidAd = styled.div`
	position: relative;
	width: 100%;
	overflow: hidden;
	background: ${theme.colors.background[0]};
	padding: 0;
	padding-top: ${theme.size(3)};
	> * {
		max-width: 100%;
	}
`;

export default props => {
	let feedAllPostsMin = allPostsMin;
	shuffle(feedAllPostsMin);
	feedAllPostsMin = [...feedAllPostsMin, ...feedAllPostsMin];
	return (
		<Layout>
			<Wrapper>
				<TopAd />
				<Page>
					<Main>
						<Post {...props} />
						<MidAd>
							<Ad type="mpu_4"></Ad>
							<Ad type="taboola-below-article-thumbnails" />
						</MidAd>
						<div className="feed">
							<Feed data={feedAllPostsMin.slice(0, 6)} />
							<Ad type="mpu_5"></Ad>
							<Ad type="leaderboard_bot_2" />
							<Feed data={feedAllPostsMin.slice(6, 12)} />
							<Ad type="leaderboard_bot_3" />
							<Feed data={feedAllPostsMin.slice(12, 18)} />
							<Ad type="leaderboard_bot_4" />
							<Feed data={feedAllPostsMin.slice(18, 24)} />
							<Ad type="leaderboard_bot_5" />
						</div>
					</Main>
					<Side>
						<Sidebar />
					</Side>
				</Page>
			</Wrapper>
		</Layout>
	);
};
