import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';

const Content = styled.div`
	${theme.type.p1};
	padding: ${theme.size(4)};
	max-width: 100%;
	overflow: hidden;
	> * {
		width: 100%;
		margin-bottom: ${theme.size(2)};
	}
	h1 {
		${theme.type.h1};
		${theme.media.notlarge} {
			font-size: 1.5em;
		}
	}
	h2 {
		${theme.type.h2};
		${theme.media.notlarge} {
			font-size: 1.25em;
		}
	}
	h3 {
		${theme.type.p1};
		font-weight: bold;
	}
	p {
		${theme.type.p1};
		p {
			padding: 0;
		}
	}
	a {
		color: ${theme.colors.base[1]};
	}
	small {
		${theme.type.p1};
		color: ${theme.colors.foreground[1]};
		margin-bottom: ${theme.size(2)};
	}
	img {
		width: 100%;
		border-radius: ${theme.size(0.5)};
		margin-bottom: ${theme.size(2)};
	}
`;

export default props => {
	return <Content>{props.children}</Content>;
};
