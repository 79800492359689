import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import axios from 'axios';
import cookie from 'react-cookies';

const StyledWrapper = styled.div`
	padding: ${theme.size(2)} ${theme.size(4)} ${theme.size(4)};
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius:${theme.size(1)};
	display: block;
	textarea {
		width: 100%;
		display: block;
		border: 0;
		outline: none;
		padding: ${theme.size(4)};
		background: rgba(0, 0, 0, 0.05);
		${theme.type.p1};
		border-radius: ${theme.size(1)};
	}
	label {
		display: block;
		padding-bottom ${theme.size(1)};
	}
	p {
		${theme.type.p2};
		padding-bottom: ${theme.size(2)};
	}
	button {
		border: 0;
		outline: none;
		${theme.type.p1};
		padding: ${theme.size(2)};
		cursor: pointer;
		width: 100%;
		text-align: center;
		border-radius: ${theme.size(1)};
		color: #fff;
		&:hover {
			opacity: 0.8;
		}
		&.--active{
			background: ${theme.colors.base[1]};
		}
		&.--inactive{
			background: rgba(0,0,0,0.2);
			pointer-events:none;
		}
	}
`;

export default props => {
	const [currentText, setCurrentText] = useState(false);
	const [text, setText] = useState(false);

	useEffect(() => {
		if (cookie.load('customtexteditor') === 'true' && text === false) {
			loadData();
		}
	});

	function loadData() {
		axios
			.get('/.netlify/functions/customTags/', {
				params: { data: JSON.stringify({ id: props.id }) },
			})
			.then(res => {
				setText(res.data.text);
				setCurrentText(res.data.text);
			})
			.catch(err => {
				console.log('ERROR!', err);
			});
	}

	function handleSave() {
		axios
			.get('/.netlify/functions/customTags/', {
				params: { data: JSON.stringify({ id: props.id, text: text }) },
			})
			.then(res => {
				setText(res.data.text);
				setCurrentText(res.data.text);
			})
			.catch(err => {
				console.log('ERROR!', err);
			});
	}

	function handleChange(event) {
		setText(event.target.value);
	}

	if (cookie.load('customtexteditor') === 'true') {
		return (
			<StyledWrapper>
				<label>
					<p>Custom text editor (ID: {props.id})</p>
					<textarea placeholder="Add text here" value={text ? text : ''} onChange={handleChange}></textarea>
				</label>
				<button onClick={handleSave} className={text === currentText ? '--inactive' : '--active'}>
					Save text
				</button>
			</StyledWrapper>
		);
	} else {
		return <></>;
	}
};
