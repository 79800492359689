import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';

const Social = styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
	${theme.media.large} {
		grid-gap: ${theme.size(1)};
	}
	border-radius: ${theme.size(1)};
	overflow: hidden;
	margin-bottom: 0;
	a {
		background: ${theme.colors.background[1]};
		color: ${theme.colors.background[0]};
		padding: ${theme.size(2)};
		cursor: pointer;
		width: 100%;
		display: grid;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		${theme.media.large} {
			border-radius: ${theme.size(1)};
		}
		&:hover {
			opacity: 0.8;
		}
		svg {
			fill: ${theme.colors.background[0]};
			height: 18px;
			display: inline-block;
		}
		&.facebook {
			background: #3b5998;
		}
		&.twitter {
			background: #1da1f2;
		}
		&.whatsapp {
			background: #25d366;
		}
		&.messenger {
			background: #0084ff;
		}
		&.pinterest {
			background: #bd081c;
		}
		&.flipboard {
			background: #e12828;
		}
	}
`;

const icons = {
	facebook: (
		<svg viewBox="0 0 24 24">
			<path
				className="fill"
				d="M13.9349 23.8438C13.8349 23.8601 13.7346 23.8751 13.6339 23.8889C13.7346 23.8751 13.8349 23.8601 13.9349 23.8438Z"
			/>
			<path
				className="fill"
				d="M14.1749 23.8028C14.1271 23.8116 14.0793 23.8196 14.0316 23.8279C14.0793 23.8196 14.1271 23.8116 14.1749 23.8028Z"
			/>
			<path
				className="fill"
				d="M13.3658 23.9222C13.2488 23.9354 13.131 23.9471 13.0129 23.957C13.131 23.9471 13.2488 23.9354 13.3658 23.9222Z"
			/>
			<path
				className="fill"
				d="M13.5963 23.894C13.5401 23.9015 13.4835 23.9084 13.4271 23.915C13.4835 23.9084 13.5401 23.9015 13.5963 23.894Z"
			/>
			<path
				className="fill"
				d="M14.5016 23.738C14.4595 23.7469 14.4172 23.7559 14.3749 23.7645C14.4172 23.7559 14.4595 23.7469 14.5016 23.738Z"
			/>
			<path
				className="fill"
				d="M15.3124 23.5366C15.2783 23.5463 15.2443 23.5554 15.2102 23.5649C15.2443 23.5554 15.2783 23.5463 15.3124 23.5366Z"
			/>
			<path
				className="fill"
				d="M15.0672 23.6036C15.0302 23.6133 14.993 23.6232 14.9559 23.6325C14.993 23.6232 15.0302 23.6135 15.0672 23.6036Z"
			/>
			<path
				className="fill"
				d="M14.7464 23.6838C14.7072 23.6929 14.6677 23.7015 14.6281 23.7103C14.6677 23.7015 14.7072 23.6929 14.7464 23.6838Z"
			/>
			<path
				className="fill"
				d="M13.0091 23.9573C12.9459 23.9626 12.8828 23.9674 12.8194 23.9716C12.8828 23.9674 12.9459 23.9626 13.0091 23.9573Z"
			/>
			<path
				className="fill"
				d="M24 12C24 5.3736 18.6264 0 12 0C5.3736 0 0 5.3736 0 12C0 18.6264 5.3736 24 12 24C12.0705 24 12.1406 23.9985 12.2109 23.9973V14.6558H9.63281V11.6512H12.2109V9.44019C12.2109 6.87598 13.7763 5.48035 16.0637 5.48035C17.159 5.48035 18.1005 5.56201 18.375 5.59845V8.27838H16.7977C15.5533 8.27838 15.3124 8.86981 15.3124 9.73755V11.6512H18.2878L17.9 14.6558H15.3124V23.5364C20.3282 22.0984 24 17.4774 24 12Z"
			/>
			<path
				className="fill"
				d="M12.7928 23.9733C12.668 23.9813 12.5425 23.9877 12.4167 23.9919C12.5425 23.9877 12.668 23.9813 12.7928 23.9733Z"
			/>
			<path
				className="fill"
				d="M12.4103 23.9923C12.3441 23.9945 12.2776 23.9962 12.2111 23.9973C12.2776 23.9962 12.3441 23.9945 12.4103 23.9923Z"
			/>
		</svg>
	),
	instagram: (
		<svg viewBox="0 0 512 512">
			<path
				className="fill"
				d="m305 256c0 27.0625-21.9375 49-49 49s-49-21.9375-49-49 21.9375-49 49-49 49 21.9375 49 49zm0 0"
			/>
			<path
				className="fill"
				d="m370.59375 169.304688c-2.355469-6.382813-6.113281-12.160157-10.996094-16.902344-4.742187-4.882813-10.515625-8.640625-16.902344-10.996094-5.179687-2.011719-12.960937-4.40625-27.292968-5.058594-15.503906-.707031-20.152344-.859375-59.402344-.859375-39.253906 0-43.902344.148438-59.402344.855469-14.332031.65625-22.117187 3.050781-27.292968 5.0625-6.386719 2.355469-12.164063 6.113281-16.902344 10.996094-4.882813 4.742187-8.640625 10.515625-11 16.902344-2.011719 5.179687-4.40625 12.964843-5.058594 27.296874-.707031 15.5-.859375 20.148438-.859375 59.402344 0 39.25.152344 43.898438.859375 59.402344.652344 14.332031 3.046875 22.113281 5.058594 27.292969 2.359375 6.386719 6.113281 12.160156 10.996094 16.902343 4.742187 4.882813 10.515624 8.640626 16.902343 10.996094 5.179688 2.015625 12.964844 4.410156 27.296875 5.0625 15.5.707032 20.144532.855469 59.398438.855469 39.257812 0 43.90625-.148437 59.402344-.855469 14.332031-.652344 22.117187-3.046875 27.296874-5.0625 12.820313-4.945312 22.953126-15.078125 27.898438-27.898437 2.011719-5.179688 4.40625-12.960938 5.0625-27.292969.707031-15.503906.855469-20.152344.855469-59.402344 0-39.253906-.148438-43.902344-.855469-59.402344-.652344-14.332031-3.046875-22.117187-5.0625-27.296874zm-114.59375 162.179687c-41.691406 0-75.488281-33.792969-75.488281-75.484375s33.796875-75.484375 75.488281-75.484375c41.6875 0 75.484375 33.792969 75.484375 75.484375s-33.796875 75.484375-75.484375 75.484375zm78.46875-136.3125c-9.742188 0-17.640625-7.898437-17.640625-17.640625s7.898437-17.640625 17.640625-17.640625 17.640625 7.898437 17.640625 17.640625c-.003906 9.742188-7.898437 17.640625-17.640625 17.640625zm0 0"
			/>
			<path
				className="fill"
				d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm146.113281 316.605469c-.710937 15.648437-3.199219 26.332031-6.832031 35.683593-7.636719 19.746094-23.246094 35.355469-42.992188 42.992188-9.347656 3.632812-20.035156 6.117188-35.679687 6.832031-15.675781.714844-20.683594.886719-60.605469.886719-39.925781 0-44.929687-.171875-60.609375-.886719-15.644531-.714843-26.332031-3.199219-35.679687-6.832031-9.8125-3.691406-18.695313-9.476562-26.039063-16.957031-7.476562-7.339844-13.261719-16.226563-16.953125-26.035157-3.632812-9.347656-6.121094-20.035156-6.832031-35.679687-.722656-15.679687-.890625-20.6875-.890625-60.609375s.167969-44.929688.886719-60.605469c.710937-15.648437 3.195312-26.332031 6.828125-35.683593 3.691406-9.808594 9.480468-18.695313 16.960937-26.035157 7.339844-7.480469 16.226563-13.265625 26.035157-16.957031 9.351562-3.632812 20.035156-6.117188 35.683593-6.832031 15.675781-.714844 20.683594-.886719 60.605469-.886719s44.929688.171875 60.605469.890625c15.648437.710937 26.332031 3.195313 35.683593 6.824219 9.808594 3.691406 18.695313 9.480468 26.039063 16.960937 7.476563 7.34375 13.265625 16.226563 16.953125 26.035157 3.636719 9.351562 6.121094 20.035156 6.835938 35.683593.714843 15.675781.882812 20.683594.882812 60.605469s-.167969 44.929688-.886719 60.605469zm0 0"
			/>
		</svg>
	),
	youtube: (
		<svg viewBox="0 0 512 512">
			<path className="fill" d="m224.113281 303.960938 83.273438-47.960938-83.273438-47.960938zm0 0" />
			<path
				className="fill"
				d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm159.960938 256.261719s0 51.917969-6.585938 76.953125c-3.691406 13.703125-14.496094 24.507812-28.199219 28.195312-25.035156 6.589844-125.175781 6.589844-125.175781 6.589844s-99.878906 0-125.175781-6.851562c-13.703125-3.6875-24.507813-14.496094-28.199219-28.199219-6.589844-24.769531-6.589844-76.949219-6.589844-76.949219s0-51.914062 6.589844-76.949219c3.6875-13.703125 14.757812-24.773437 28.199219-28.460937 25.035156-6.589844 125.175781-6.589844 125.175781-6.589844s100.140625 0 125.175781 6.851562c13.703125 3.6875 24.507813 14.496094 28.199219 28.199219 6.851562 25.035157 6.585938 77.210938 6.585938 77.210938zm0 0"
			/>
		</svg>
	),
	twitter: (
		<svg viewBox="0 0 24 24">
			<path
				className="fill"
				d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z"
			/>
		</svg>
	),

	googleplus: (
		<svg viewBox="0 0 24 24">
			<path
				className="fill"
				d="M7.635 10.909v2.619h4.335c-.173 1.125-1.31 3.295-4.331 3.295-2.604 0-4.731-2.16-4.731-4.823 0-2.662 2.122-4.822 4.728-4.822 1.485 0 2.479.633 3.045 1.178l2.073-1.994c-1.33-1.245-3.056-1.995-5.115-1.995C3.412 4.365 0 7.785 0 12s3.414 7.635 7.635 7.635c4.41 0 7.332-3.098 7.332-7.461 0-.501-.054-.885-.12-1.265H7.635zm16.365 0h-2.183V8.726h-2.183v2.183h-2.182v2.181h2.184v2.184h2.189V13.09H24"
			/>
		</svg>
	),

	whatsapp: (
		<svg viewBox="0 0 24 24">
			<path
				className="fill"
				d="M17.498 14.382c-.301-.15-1.767-.867-2.04-.966-.273-.101-.473-.15-.673.15-.197.295-.771.964-.944 1.162-.175.195-.349.21-.646.075-.3-.15-1.263-.465-2.403-1.485-.888-.795-1.484-1.77-1.66-2.07-.174-.3-.019-.465.13-.615.136-.135.301-.345.451-.523.146-.181.194-.301.297-.496.1-.21.049-.375-.025-.524-.075-.15-.672-1.62-.922-2.206-.24-.584-.487-.51-.672-.51-.172-.015-.371-.015-.571-.015-.2 0-.523.074-.797.359-.273.3-1.045 1.02-1.045 2.475s1.07 2.865 1.219 3.075c.149.195 2.105 3.195 5.1 4.485.714.3 1.27.48 1.704.629.714.227 1.365.195 1.88.121.574-.091 1.767-.721 2.016-1.426.255-.705.255-1.29.18-1.425-.074-.135-.27-.21-.57-.345m-5.446 7.443h-.016c-1.77 0-3.524-.48-5.055-1.38l-.36-.214-3.75.975 1.005-3.645-.239-.375c-.99-1.576-1.516-3.391-1.516-5.26 0-5.445 4.455-9.885 9.942-9.885 2.654 0 5.145 1.035 7.021 2.91 1.875 1.859 2.909 4.35 2.909 6.99-.004 5.444-4.46 9.885-9.935 9.885M20.52 3.449C18.24 1.245 15.24 0 12.045 0 5.463 0 .104 5.334.101 11.893c0 2.096.549 4.14 1.595 5.945L0 24l6.335-1.652c1.746.943 3.71 1.444 5.71 1.447h.006c6.585 0 11.946-5.336 11.949-11.896 0-3.176-1.24-6.165-3.495-8.411"
			/>
		</svg>
	),

	messenger: (
		<svg viewBox="0 0 24 24">
			<path
				className="fill"
				d="M12 0C5.373 0 0 4.974 0 11.111c0 3.498 1.744 6.614 4.469 8.654V24l4.088-2.242c1.092.3 2.246.464 3.443.464 6.627 0 12-4.975 12-11.11S18.627 0 12 0zm1.191 14.963l-3.055-3.26-5.963 3.26L10.732 8l3.131 3.259L19.752 8l-6.561 6.963z"
			/>
		</svg>
	),

	mail: (
		<svg viewBox="0 0 91 91">
			<path
				className="fill"
				d="M45.5,0.291c-24.966,0-45.208,20.24-45.208,45.209S20.534,90.709,45.5,90.709c24.967,0,45.209-20.24,45.209-45.209     S70.467,0.291,45.5,0.291z M71.484,26.498L45.5,47.283L19.516,26.498C19.825,26.408,71.18,26.408,71.484,26.498z M17.249,28.717     l18.555,15.889L17.264,62.408C17.151,62.061,17.143,29.043,17.249,28.717z M19.559,64.584l18.719-16.6l7.222,5.877l7.234-5.887     l18.713,16.609C71.148,64.666,19.854,64.666,19.559,64.584z M73.734,62.408L55.205,44.598l18.549-15.881     C73.859,29.043,73.855,62.061,73.734,62.408z"
			/>
		</svg>
	),
	pinterest: (
		<svg viewBox="0 0 24 24">
			<path
				className="fill"
				d="M12.017 0C5.396 0 .029 5.367.029 11.987c0 5.079 3.158 9.417 7.618 11.162-.105-.949-.199-2.403.041-3.439.219-.937 1.406-5.957 1.406-5.957s-.359-.72-.359-1.781c0-1.663.967-2.911 2.168-2.911 1.024 0 1.518.769 1.518 1.688 0 1.029-.653 2.567-.992 3.992-.285 1.193.6 2.165 1.775 2.165 2.128 0 3.768-2.245 3.768-5.487 0-2.861-2.063-4.869-5.008-4.869-3.41 0-5.409 2.562-5.409 5.199 0 1.033.394 2.143.889 2.741.099.12.112.225.085.345-.09.375-.293 1.199-.334 1.363-.053.225-.172.271-.401.165-1.495-.69-2.433-2.878-2.433-4.646 0-3.776 2.748-7.252 7.92-7.252 4.158 0 7.392 2.967 7.392 6.923 0 4.135-2.607 7.462-6.233 7.462-1.214 0-2.354-.629-2.758-1.379l-.749 2.848c-.269 1.045-1.004 2.352-1.498 3.146 1.123.345 2.306.535 3.55.535 6.607 0 11.985-5.365 11.985-11.987C23.97 5.39 18.592.026 11.985.026L12.017 0z"
			/>
		</svg>
	),
	flipboard: (
		<svg viewBox="0 0 24 24">
			<path className="fill" d="M0 0v24h24V0H0zm19.2 9.6h-4.8v4.8H9.6v4.8H4.8V4.8h14.4v4.8z" />
		</svg>
	),
};

export default props => {
	return (
		<Social>
			{[
				{
					network: 'facebook',
					url: 'https://www.facebook.com/sharer.php?u={url}',
				},
				{
					network: 'twitter',
					url: 'https://twitter.com/intent/tweet?url={url}',
				},
				{
					network: 'whatsapp',
					url: 'whatsapp://send?text={url}',
				},
				{
					network: 'messenger',
					url: 'fb-messenger://share?link={url}',
				},
				{
					network: 'pinterest',
					url: 'http://pinterest.com/pin/create/button/?url={url}&media={img}',
				},
				{
					network: 'flipboard',
					url: 'https://share.flipboard.com/bookmarklet/popout?v=2&url={url}',
				},
			].map((item, index) => (
				<a
					key={index}
					className={item.network}
					href={item.url.replace('{url}', `${props.href}`).replace('{img}', `${props.image}`)}
					target={'_blank'}
				>
					{icons[item.network]}
				</a>
			))}
		</Social>
	);
};
